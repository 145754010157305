import notification from '@ctm/ui/src/components/Notification/notification.js';

function handle_error(error) {
	notification
		.open({
			title: 'Error',
			text: error || 'Please try again or reach out to support for assistance.',
			isDark: true,
			isClosable: true,
			actions: {}
		});
}

export { handle_error };