import { browser, version } from '$app/environment';
import { handle_error } from '$lib/util/handle-errors.js';

export const pendoKey = '03892b77-da84-4ae3-7a64-b2ef6b156b95';

const env = {
	'localhost:3008': {
		api: 'https://lightning-api-qa-k8s.ctmdevelopment.com',
		auth0: { client_id: '9SzG9uIuTExu3G2ztVQ439KCQcDWdJYv', domain: 'travelctm-au-qa.au.auth0.com' },
		mixpanel: { token: '', enabled: false, debug: false },
		client: { id: '8EB817E7-91EC-467B-9FD8-1E46E441CB5B', secret: 'xYAfM3r4ipF9HMWe6XM2bA9jDlqhOOZhd27tHg3v16KviEiIjZ' },
		app_insights: { enabled: false, code: '' },
		new_relic: { enabled: false },
		version
	},
	dev: {
		api: 'https://lightning-api-dev-k8s.ctmdevelopment.com',
		auth0: { client_id: '9SzG9uIuTExu3G2ztVQ439KCQcDWdJYv', domain: 'travelctm-au-development.au.auth0.com' },
		mixpanel: { token: '', enabled: false, debug: false },
		client: { id: '8EB817E7-91EC-467B-9FD8-1E46E441CB5B', secret: 'xYAfM3r4ipF9HMWe6XM2bA9jDlqhOOZhd27tHg3v16KviEiIjZ' },
		app_insights: { enabled: false, code: '' },
		new_relic: { enabled: false },
		version
	},
	qa: {
		api: 'https://lightning-api-qa-k8s.ctmdevelopment.com',
		auth0: { client_id: '9SzG9uIuTExu3G2ztVQ439KCQcDWdJYv', domain: 'travelctm-au-qa.au.auth0.com' },
		mixpanel: { token: '', enabled: false, debug: false },
		client: { id: '8EB817E7-91EC-467B-9FD8-1E46E441CB5B', secret: 'xYAfM3r4ipF9HMWe6XM2bA9jDlqhOOZhd27tHg3v16KviEiIjZ' },
		app_insights: { enabled: false, code: '' },
		new_relic: { enabled: false },
		version
	},
	qasab: {
		api: 'https://lightning-api-qasab-k8s.ctmdevelopment.com',
		auth0: { client_id: '9SzG9uIuTExu3G2ztVQ439KCQcDWdJYv', domain: 'travelctm-au-qa.au.auth0.com' },
		mixpanel: { token: '', enabled: false, debug: false },
		client: { id: '8EB817E7-91EC-467B-9FD8-1E46E441CB5B', secret: 'xYAfM3r4ipF9HMWe6XM2bA9jDlqhOOZhd27tHg3v16KviEiIjZ' },
		app_insights: { enabled: false, code: '' },
		new_relic: { enabled: false },
		version
	},
	staging: {
		api: 'https://lightning-api-staging-k8s.ctmdevelopment.com',
		auth0: { client_id: 'zI07a82a6MO3D9BbE1IpG5vNAjKv1CSs', domain: 'travelctm-au-staging.au.auth0.com' },
		mixpanel: { token: '', enabled: false, debug: false },
		client: { id: '8EB817E7-91EC-467B-9FD8-1E46E441CB5B', secret: 'xYAfM3r4ipF9HMWe6XM2bA9jDlqhOOZhd27tHg3v16KviEiIjZ' },
		app_insights: { enabled: false, code: '' },
		new_relic: { enabled: true, application_Id: '594552989' },
		version
	},
	preprod: {
		api: 'https://lightning-api-preprod-k8s.ctmdevelopment.com',
		auth0: { client_id: 'S9IbB5d7tXNlvH7BI2Nfjo6ZVnQPHa9g', domain: 'travelctm-au-production.au.auth0.com' },
		mixpanel: { token: '', enabled: false, debug: false },
		client: { id: '8EB817E7-91EC-467B-9FD8-1E46E441CB5B', secret: 'xYAfM3r4ipF9HMWe6XM2bA9jDlqhOOZhd27tHg3v16KviEiIjZ' },
		app_insights: { enabled: false, code: '' },
		new_relic: { enabled: true, application_Id: '594552997' },
		version
	},
	booking: {
		api: 'https://api.lightningbook.com.au',
		auth0: { client_id: 'S9IbB5d7tXNlvH7BI2Nfjo6ZVnQPHa9g', domain: 'travelctm-au-production.au.auth0.com' },
		mixpanel: { token: '', enabled: false, debug: false },
		client: { id: '8EB817E7-91EC-467B-9FD8-1E46E441CB5B', secret: 'xYAfM3r4ipF9HMWe6XM2bA9jDlqhOOZhd27tHg3v16KviEiIjZ' },
		app_insights: { enabled: true, code: 'a4efca09-d7c7-47c0-9128-351c30e4f5c4' },
		new_relic: { enabled: true, application_Id: '594552997' },
		version
	},
};

const host = browser && location.host.split('.')[0];
const evn_data = env[host];

export const api_url = evn_data.api;
export const auth0_domain = evn_data.auth0.domain;
export const auth0_client_id = evn_data.auth0.client_id;
export const mixpanel_token = evn_data.mixpanel.token;
export const mixpanel_debug = evn_data.mixpanel.debug;
export const mixpanel_enabled = evn_data.mixpanel.enabled;
export const client_id = evn_data.client.id;
export const client_secret = evn_data.client.secret;
export const app_version = evn_data.version;
export const app_insights_enabled = evn_data.app_insights.enabled;
export const app_insights_code = evn_data.app_insights.code;
export const new_relic_enabled = evn_data.new_relic.enabled;
export const new_relic_application_Id = evn_data.new_relic.application_Id;

/** @type {Object.<string, AbortController>} */
const abortControllers = {};

/** @type {string} */
const prod_hostname = 'booking.lightningbook.com.au';

/** @type {boolean} */
export const is_prod = document.location.hostname === prod_hostname;

/** @type {undefined|string} */
export const override_api_for_localhost = localStorage.getItem('api-override');

if (override_api_for_localhost) {
	console.log(`%cYou are now running in localhost:${override_api_for_localhost} mode!`, 'color: blue; font-size: 20px; background-color: yellow; padding: 5px; border-radius: 5px;');
	console.log(`%cTo disable run reset_localhost_api()`, 'color: blue; font-size: 20px; background-color: yellow; padding: 5px; border-radius: 5px;');
}

/** @type {URL} */
export const apiUrl = override_api_for_localhost ? 'https://localhost:' + override_api_for_localhost : api_url;

export function clear_localstorage() {
	const api_override = localStorage.getItem('api-override');
	localStorage.clear();
	if (api_override) localStorage.setItem('api-override', api_override);
}

/**
 * @async
 * @param {string} path
 * @param {Object} options
 * @param {('GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE')} [options.method='GET']
 * @param {string} [options.version='20']
 * @param {boolean} [options.skipAbort=false]
 * @param {any} [options.fetch]
 * @param {any} [options.params]
 * @param {any} [body]
 */
export async function api_request(
	path,
	options = {
		method: 'GET',
		version: '20',
		skipAbort: false,
		fetch: null,
		params: null,
		overrideError: null
	},
	body
) {
	if (!options.skipAbort && abortControllers[path]) {
		abortControllers[path].abort();
		delete abortControllers[path];
	}

	try {
		const _oauth = sessionStorage.getItem('oauth');
		if (!_oauth) return;

		abortControllers[path] = new AbortController();
		const token = JSON.parse(_oauth).access_token;
		const $fetch = options.fetch || fetch;
		const args = {};
		args.method = options.method;
		args.signal = abortControllers[path].signal;
		args.headers = new Headers({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
			'X-Api-Version': options.version || '20'
		});

		if (body) args.body = JSON.stringify(body);

		const current_debtor = sessionStorage.getItem('CURRENT_USER_debtor');
		if (current_debtor) {
			args.headers.append('X-Api-Debtor', JSON.parse(current_debtor).Name);
		}

		let params = '?' + new URLSearchParams(options.params).toString();
		if (params.length < 2) params = '';

		const res = await $fetch(`${apiUrl}/api/${path}${params}`, args);
		const text = await res.text();

		if (text.trim().length === 0) { return null; };

		let json = null;

		try {
			json = JSON.parse(text);
		} catch (err) {
			throw new Error(err);
		}

		if (!res.ok) {
			throw new Error();
		}

		delete abortControllers[path];
		return json;
	} catch (err) {
		if (options.overrideError) {
			options.overrideError(err);
		} else if (err.name === 'AbortError') {
			console.log('Fetch was aborted');
		} else {
			handle_error();
			throw new Error();
		}
	}
}
